<template>
  <SlDialog
    title="成本复核"
    :visible.sync="dialogVisible"
    :width="dialogWidth"
    :loading="handleLoading"
    @cancel="cancelHandler"
    @submit="submitHandler"
  >
    <div v-loading="loading">
      <SlTable
        ref="table"
        align="left"
        :selection="false"
        :border="true"
        :tableData="tableData"
        :columns="columns"
        :operate="false"
        :tooltip="false"
        :isEmbedTable="true"
        maxHeight="200px"
      ></SlTable>
      <el-form
        class="review-form mt-1rem"
        ref="form"
        :model="form"
        :rules="rules"
        :validate-on-rule-change="false"
        label-width="100px"
      >
        <el-form-item label="复核原因" prop="applyType">
          <el-select
            class="item-style"
            v-model="form.applyType"
            placeholder="请选择"
            filterable
            clearable
          >
            <el-option
              v-for="(item,index) in applyTypeOptions"
              :key="'opt_'+index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="图片" prop="attachmentUrl">
          <SlUploadImages
            v-model="form.attachmentUrl"
            folder="purchasePriceRecheck"
            :imageType="7"
            :limit="3"
            :tools="['download', 'delete']"
            :limits="[{type: 'size',meta: {size: 1}}]"
            :multiple="true"
          ></SlUploadImages>
          <p class="font-size-12 color-text--minor">图片大小小于1M,最多上传3张。</p>
        </el-form-item>
        <el-form-item label="原因备注" prop="applyRemark">
          <el-input
            class="item-style"
            type="textarea"
            v-model="form.applyRemark"
            placeholder="不超过50字"
            maxlength="50"
            rows="3"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
  </SlDialog>
</template>
<script>
import { emptyValidator } from '@shared/validate'
import OemGoodsAPI from '@api/oemGoods'
import CommonApi from '@api/api.js'

export default {
  name: 'PriceReviewDialog',
  props: {},
  data () {
    return {
      loading: false,
      handleLoading: false,
      dialogVisible: false,
      dialogWidth: '50%',
      form: {
        applyType: null,
        applyRemark: null,
        attachmentUrl: [],
        itemRequestList: []
      },
      rules: {
        applyType: [
          emptyValidator('请选择原因', ['blur', 'change'])
        ]
      },
      applyTypeOptions: [],
      tableData: [],
      columns: [
        {
          prop: 'purchaseOrderNumber',
          label: '生产订单号',
          width: '100'
        },
        {
          prop: 'skuCode',
          label: 'SKU'
        },
        {
          prop: 'skuImage',
          label: '商品图片',
          width: '60',
          isImg: true,
          data: {
            imgSize: '50px'
          }
        },
        {
          prop: 'name',
          label: '商品名称',
          render: (h, data) => {
            let { row = {} } = data
            return (
              <el-tooltip placement="top" effect="light">
                <div slot="content" style="max-width:150px">{row.name}</div>
                <p v-slClamp={{ clamp: 2 }}>{row.name}</p>
              </el-tooltip>
            )
          }
        },
        {
          prop: 'attributesName',
          label: '销售属性',
          width: '100'
        },
        {
          prop: 'originPrice',
          label: '原价'
        }
      ]
    }
  },
  mounted () {
    CommonApi.getDict({ dataCode: 'PURCHASE_PRICE_RECHECK_APPLY_TYPE_ENUM' }).then(data => {
      this.applyTypeOptions = data.map(item => ({ label: item.label, value: item.label }))
    })
  },
  methods: {
    openDialog (rows) {
      this.loading = true
      this.tableData = rows
      this.dialogVisible = true
      this.loading = false
    },
    submitHandler () {
      this.$refs.form.clearValidate()
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.handleLoading = true

          let submitData = {
            applyRemark: this.form.applyRemark,
            applyType: this.form.applyType
          }
          submitData.attachmentUrl = this.form.attachmentUrl.map(item => item.src)
          submitData.itemRequestList = this.tableData.map(item => ({ purchaseOrderNumber: item.purchaseOrderNumber, skuCode: item.skuCode } = item))
          OemGoodsAPI.priceRecheckApply(submitData).then(res => {
            let { success } = res
            if (success) {
              this.dialogVisible = false
              this.$message.success(`申请成功`)
              this.$refs.form.resetFields()
              this.$emit('submit')
            }
          }).finally(() => {
            this.handleLoading = false
          })
        }
      })
    },
    cancelHandler () {
      this.$refs.form.resetFields()
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .review-form {
  textarea {
    resize: none;
  }

  .item-style {
    width: 50%;
    min-width: 300px;
  }
}
</style>
