<template>
  <el-dialog
    width="600px"
    :append-to-body="true"
    :before-close="closeHandle"
    :close-on-click-modal="false"
    :lock-scroll="false"
    :visible.sync="visible"
    title="到货地址"
  >
    <p class="is-required">{{ data.supplierName }}</p>
    <p class="is-required">{{ data.province + data.city + data.district + data.detailAddress }}</p>
    <p class="is-required">{{ data.recipientName }} {{ data.phoneNumber }}</p>
    <span class="dialog-footer" slot="footer">
      <el-button @click="closeHandle">关闭</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: 'SupplierDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    closeHandle () {
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.is-required {
  line-height: 24px;
  padding-left: 15px;
  margin-bottom: 8px;
  &::before {
    content: '*';
    color: red;
    margin-right: 4px;
    font-weight: 600;
  }
}
</style>
